import React from 'react'
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import { graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import Layout from "../components/layout";
import ReactCompareImage from 'react-compare-image';

const parallax = {
    backgroundImage: 'url(../recrmIoROuVHVNlly.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
};

const paraOne = {
    backgroundImage: 'url(../recaFLmPyhx6j91ql.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const rec4wCi41dQgqYWDe = {
    backgroundImage: 'url(../rec4wCi41dQgqYWDe.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recGA8puOW3rzDgZL = {
    backgroundImage: 'url(../recGA8puOW3rzDgZL.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraTwo = {
    backgroundImage: 'url(../rec6SfBtbmj68bzWK.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraThree = {
    backgroundImage: 'url(../recziPgcigNJUbhKJ.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraFour = {
    backgroundImage: 'url(../rec2mczTtQPvykUOG.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraFive = {
    backgroundImage: 'url(../rec1usupYv5i2u0Za.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const credit = {
    background: 'linear-gradient(rgba(255, 184, 29, 0.45), rgba(255, 184, 29, 0.45)), url(../recXqVP8G2TkQh1qo.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundPosition: '50% 40%',
};

export default ({ data }) => (
    <div>
        <Layout>
        <Helmet>
            <title>{data.meta.data.story} | Oberlin Photo Stories</title>
            
            <meta name="title" content={data.meta.data.story} />
            <meta name="description" content={data.meta.data.description} />
            
            <meta property="og:type" content="website" />
            <meta property="og:url" content={data.meta.data.metaurl} />
            <meta property="og:title" content={data.meta.data.story} />
            <meta property="og:description" content={data.meta.data.description} />
            <meta property="og:image" content="https://photo-stories.oberlin.edu/recrmIoROuVHVNlly.jpg" />
            <meta property="og:image:width" content="2000" />
            <meta property="og:image:height" content="1386" />
            <meta property="og:image:alt" content={data.meta.data.alt} />
            <meta property="fb:app_id" content="1571317633107125" />
            
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={data.meta.data.metaurl} />
            <meta property="twitter:title" content={data.meta.data.story} />
            <meta property="twitter:description" content={data.meta.data.description} />
            <meta property="twitter:image" content="https://photo-stories.oberlin.edu/recrmIoROuVHVNlly.jpg" />
            <meta property="twitter:image:alt" content={data.meta.data.alt} />
        </Helmet>
        {/* hero */}
        <header>
            <div className="wrapper">
                <div style={parallax} className="hero parallax" role="img" aria-label={data.meta.data.alt}></div>
                <div className="title text-center">
                    <h1 className="display-3 text-light">{data.title.data.title}</h1>
                    <p className="lead text-light">- {data.subtitle.data.title} -</p>
                    <a href="#ss" aria-label="Skip to main content"><span className="fas fa-chevron-down fa-3x mt-5 text-white"></span></a>
                </div>
            </div>
        </header>

        <main>
            <div className="anchor" id="ss"></div>
            <div className="container mt-5 story">
                {/* intro */}
                <section>
                <div className="col-lg-10 text-center mx-auto lead mt-5" dangerouslySetInnerHTML={{
                __html: data.intro.data.text.childMarkdownRemark.html,
                }} />
                <div className="py-5">
                <Fade bottom>
                <Img fluid={data.recAWh5ohTS84K2HZ.data.file.localFiles[0].childImageSharp.fluid} alt={data.recAWh5ohTS84K2HZ.data.alt} />
                </Fade>
                </div>
                {/* Cozy warm comfortable */}
                <Fade>
                <div className="row pola-group mt-5">
                {data.polaroid.nodes.map(node => (
                    <div className="col-lg-4 col-md-6 col-10 mx-auto mb-md-3 mb-0" key={node.recordId}>
                        <div className={node.data.polaroid_class}>
                            <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} className="card-img-top filter-earlybird" />
                            <div className="card-body text-center">
                                <p className="card-text pola-caption">{node.data.title}</p>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
                </Fade>
                </section>
                {/* poster deign */}
                <section>
                <Fade left>
                <div dangerouslySetInnerHTML={{
                __html: data.recsUT6Mza834q8jB.data.text.childMarkdownRemark.html,
                }} className="pt-5 mt-5" />
                </Fade>
                <Fade bottom>
                {data.recyDvMeq8rJLAQIP.nodes.map(node => (
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="mt-3" />
                ))} 
                </Fade>
                </section>
                {/* art and tapestry */}
                <section>
                <Fade left>
                <div dangerouslySetInnerHTML={{
                __html: data.recgX59BdwQFggxor.data.text.childMarkdownRemark.html,
                }} className="mt-5 pt-5" />
                </Fade>
                <Pulse>
                <div className="row no-gutters">
                    {data.grid.nodes.map(node => (
                        <Fade key={node.recordId}>
                        <div className="col-lg-4 col-6">
                            <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} className="m-1" />
                        </div>
                        </Fade>
                    ))}
                </div>
                </Pulse>
                </section>
                {/* floor decor */}
                <section>
                <Fade right>
                <div dangerouslySetInnerHTML={{
                __html: data.recWF0GcIvyydeDcf.data.text.childMarkdownRemark.html,
                }} className="mt-5 pt-5" />
                </Fade>
                </section>
            </div>
            <section>
            <div style={paraOne} role="img" aria-label={data.recaFLmPyhx6j91ql.data.alt} className="parallax mb-5"></div>
            <div style={rec4wCi41dQgqYWDe} role="img" aria-label={data.rec4wCi41dQgqYWDe.data.alt} className="parallax mb-5"></div>
            <div style={recGA8puOW3rzDgZL} role="img" aria-label={data.recGA8puOW3rzDgZL.data.alt} className="parallax"></div>
            </section>
            <div className="container my-4 story">
                {/* century advantage */}
                <section>
                <Fade left>
                <div dangerouslySetInnerHTML={{
                __html: data.recGfQ4yGNX6d7KZ2.data.text.childMarkdownRemark.html,
                }} className="mt-5 pt-5" />    
                </Fade>
                <Fade bottom>
                <Img fluid={data.rec5dRcOuh2BKyhkL.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec5dRcOuh2BKyhkL.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.rec5dRcOuh2BKyhkL.data.text.childMarkdownRemark.html,
                }} />
                </Fade>
                </section>
                {/* tiny touches */}
                <section>
                <Fade right>
                <div dangerouslySetInnerHTML={{
                __html: data.recQoUe55DiXUtUHU.data.text.childMarkdownRemark.html,
                }} className="mt-5 pt-5" />    
                </Fade>
                <div className="row">
                {data.recdbTv0VsLE9wbEq.nodes.map(node => (
                    <div className="col-lg-4 col-12" key={node.recordId}>
                        <Img fixed={node.data.file.localFiles[0].childImageSharp.fixed} alt={node.data.alt} className="w-100" />
                    </div>
                ))}
                </div>
                </section>
            </div>
            <section>
            <div style={paraTwo} role="img" aria-label={data.rec6SfBtbmj68bzWK.data.alt} className="parallax"></div>
            </section>
            <div className="container my-4 story">
                <section className="pb-5">
                <div className="row">
                {data.recuVHORlPevLGlqZ.nodes.map(node => (
                    <div className="col-lg-4 col-12 mb-3" key={node.recordId}>
                        <Img fixed={node.data.file.localFiles[0].childImageSharp.fixed} alt={node.data.alt} className="w-100" />
                    </div>
                ))}
                </div>
                </section>
                {/* art rental */}
                <section>
                <div className="row mt-5">
                    <div className="col-lg-6 mb-lg-0 mb-3">
                        <Img fixed={data.recikyUgtm3DwWe8k.data.file.localFiles[0].childImageSharp.fixed} alt={data.recikyUgtm3DwWe8k.data.alt} className="w-100" />
                    </div>
                    <div className="col-lg-6 story">
                        <div dangerouslySetInnerHTML={{
                        __html: data.recikyUgtm3DwWe8k.data.text.childMarkdownRemark.html,
                        }} />
                    </div>
                </div>
                </section>
            </div>

            <div style={paraThree} role="img" aria-label={data.recziPgcigNJUbhKJ.data.alt} className="parallax"></div>

            <div className="container my-4 story">
            <Fade bottom>
            <Img fluid={data.rec62FX4S0MAbvk3h.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec62FX4S0MAbvk3h.data.alt} />
            </Fade>
            </div>

            <div style={paraFour} role="img" aria-label={data.rec2mczTtQPvykUOG.data.alt} className="parallax mb-5"></div>

            <div style={paraFive} role="img" aria-label={data.rec1usupYv5i2u0Za.data.alt} className="parallax"></div>

            <div className="container mt-4 story">
            <Fade bottom>
            <Img fluid={data.recBDY5cOOojKK5mn.data.file.localFiles[0].childImageSharp.fluid} alt={data.recBDY5cOOojKK5mn.data.alt} />
            </Fade>
            </div>
            
            <div className="container story">
            {/* shared design */}
            <section>
            <Fade left>
            <div dangerouslySetInnerHTML={{
            __html: data.recmM5UgCPReUx4k4.data.text.childMarkdownRemark.html,
            }} className="mt-5 pt-5" />
            </Fade>
            <div className="row">
            <Slide left>
            <div className="col-lg-6 mb-3" key={data.recvjaLHJwFdJcMZZ.data.recordId}>
                <Img fixed={data.recvjaLHJwFdJcMZZ.data.file.localFiles[0].childImageSharp.fixed} alt={data.recvjaLHJwFdJcMZZ.data.alt} className="w-100" />
            </div>
            </Slide>
            <Slide right>
            <div className="col-lg-6 mb-3" key={data.recUlg8aGrC1gND7X.data.recordId}>
                <Img fixed={data.recUlg8aGrC1gND7X.data.file.localFiles[0].childImageSharp.fixed} alt={data.recUlg8aGrC1gND7X.data.alt} className="w-100" />
            </div>
            </Slide>
            <Slide left>
            <div className="col-lg-6 mb-lg-0 mb-3" key={data.recBW9xXjKs66MFWZ.data.recordId}>
                <Img fixed={data.recBW9xXjKs66MFWZ.data.file.localFiles[0].childImageSharp.fixed} alt={data.recBW9xXjKs66MFWZ.data.alt} className="w-100" />
            </div>
            </Slide>
            <Slide right>
            <div className="col-lg-6 mb-lg-0 mb-3" key={data.reciSaKWZ4Vz9lWUK.data.recordId}>
                <Img fixed={data.reciSaKWZ4Vz9lWUK.data.file.localFiles[0].childImageSharp.fixed} alt={data.reciSaKWZ4Vz9lWUK.data.alt} className="w-100" />
            </div>
            </Slide>
            </div>
            </section>
            {/* tips */}
            <section>
            <Fade right>
            <div dangerouslySetInnerHTML={{
            __html: data.recolk2pDRq56lefs.data.text.childMarkdownRemark.html,
            }} className="mt-5 pt-5" />
            </Fade>
            <ReactCompareImage
                leftImage="https://dl.airtable.com/.attachmentThumbnails/31f4cd8e4b61102634a46314c7150ada/a1e873a0"
                leftImageLabel="Before"
                leftImageAlt="Room 127 in Barrows Hall before the makeover."
                rightImage="https://dl.airtable.com/.attachmentThumbnails/4a99d9eb9dd6f3b66fe6d11067e19711/14586cc7"
                rightImageLabel="After"
                rightImageAlt="Room 127 in Barrows Hall after the makeover."
                sliderPositionPercentage="0.3"
            />
            <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
            __html: data.recuZC3oyU650YG4R.data.text.childMarkdownRemark.html,
            }} />
            <div className="row mt-5">
            <div className="col-lg-4 col-12 mb-lg-5 mb-3">
            <Pulse>
            <Img fixed={data.rectk3ob4xIAtXe6E.data.file.localFiles[0].childImageSharp.fixed} alt={data.rectk3ob4xIAtXe6E.data.alt} className="w-100" />
            </Pulse>
            </div>
            <div className="col-lg-4 col-12 mb-lg-5 mb-3">
            <Pulse>
            <Img fixed={data.recIA3pDRKp9EyeL8.data.file.localFiles[0].childImageSharp.fixed} alt={data.recIA3pDRKp9EyeL8.data.alt} className="w-100" />
            </Pulse>
            </div>
            <div className="col-lg-4 col-12 mb-lg-5 mb-3">
            <Pulse>
            <Img fixed={data.rec4jZVV94eyRhfYC.data.file.localFiles[0].childImageSharp.fixed} alt={data.rec4jZVV94eyRhfYC.data.alt} className="w-100" />
            </Pulse>
            </div>
            <div className="col-lg-6 mb-lg-0 mb-3">
            <Img fixed={data.recqwwiyTXvgqVuuW.data.file.localFiles[0].childImageSharp.fixed} alt={data.recqwwiyTXvgqVuuW.data.alt} className="w-100" />
            </div>
            <div className="col-lg-6 mb-lg-0 mb-3">
            <Img fixed={data.recIUv5l0lpy0kh0b.data.file.localFiles[0].childImageSharp.fixed} alt={data.recIUv5l0lpy0kh0b.data.alt} className="w-100" />
            </div>
            </div>
            </section>
            {/* final touches */}
            <section>
            <Fade left>
            <div dangerouslySetInnerHTML={{
            __html: data.recQ1BiG8ENmDFflK.data.text.childMarkdownRemark.html,
            }} className="mt-5 pt-5" />
            </Fade>
            <div className="row pola-group mt-4">
            {data.gridTwo.nodes.map(node => (
                <div className="col-lg-4 col-md-6 col-10 mx-auto mb-md-3 mb-0" key={node.recordId}>
                    <div className={node.data.polaroid_class}>
                        <Zoom>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} className="card-img-top" />
                        </Zoom>
                        <div className="card-body text-center">
                            <p className="card-text pola-caption">{node.data.title}</p>
                        </div>
                    </div>
                </div>
            ))}
            </div>
            </section>
            </div>
            <section>
                <div style={credit} role="img" aria-label={data.recXqVP8G2TkQh1qo.data.alt} className="py-5 mb-0 mt-5 text-center">
                    <div className="credit-text">
                        <p className="h3 credit-title">{data.recXqVP8G2TkQh1qo.data.title}</p>
                        <div dangerouslySetInnerHTML={{
                        __html: data.recXqVP8G2TkQh1qo.data.text.childMarkdownRemark.html,
                        }} />
                    </div>
                </div>
            </section>
        </main>
        </Layout>
    </div>
)

export const query = graphql`
{
    meta: airtable(recordId: {eq: "recrmIoROuVHVNlly"}) {
        data {
          file {
            localFiles {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          alt
          story
          metaurl
          description
        }
      }

    title: airtable(data: {identifier: {eq: "recXju5Slxvn9d70Z"}}) {
        data {
            title
        }
    }

    subtitle: airtable(data: {identifier: {eq: "recJ5BJcLDZ8EdgXr"}}) {
        data {
            title
        }
    }

    intro: airtable(data: {identifier: {eq: "reckWnWi5fNreWA76"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recAWh5ohTS84K2HZ: airtable(data: {identifier: {eq: "recAWh5ohTS84K2HZ"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    polaroid: allAirtable(filter: {data: {group: {eq: "room5"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(cropFocus: ATTENTION, maxHeight: 360, maxWidth: 360) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                title
                polaroid_class
            }         
        }
    }

    recsUT6Mza834q8jB: airtable(data: {identifier: {eq: "recsUT6Mza834q8jB"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recyDvMeq8rJLAQIP: allAirtable(filter: {data: {group: {eq: "room7"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recgX59BdwQFggxor: airtable(data: {identifier: {eq: "recgX59BdwQFggxor"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    grid: allAirtable(filter: {data: {group: {eq: "room9"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(cropFocus: ATTENTION, maxHeight: 370, maxWidth: 370) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recWF0GcIvyydeDcf: airtable(data: {identifier: {eq: "recWF0GcIvyydeDcf"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recaFLmPyhx6j91ql: airtable(recordId: {eq: "recaFLmPyhx6j91ql"}) {
        data {
            alt
        }
    }

    rec4wCi41dQgqYWDe: airtable(recordId: {eq: "rec4wCi41dQgqYWDe"}) {
        data {
            alt
        }
    }

    recGA8puOW3rzDgZL: airtable(recordId: {eq: "recGA8puOW3rzDgZL"}) {
        data {
            alt
        }
    }

    recGfQ4yGNX6d7KZ2: airtable(data: {identifier: {eq: "recGfQ4yGNX6d7KZ2"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec5dRcOuh2BKyhkL: airtable(data: {identifier: {eq: "rec5dRcOuh2BKyhkL"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recQoUe55DiXUtUHU: airtable(data: {identifier: {eq: "recQoUe55DiXUtUHU"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recdbTv0VsLE9wbEq: allAirtable(filter: {data: {group: {eq: "room18"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fixed(cropFocus: ATTENTION, height: 300, quality: 85) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
                alt
            }
        }
    }
    
    rec6SfBtbmj68bzWK: airtable(recordId: {eq: "rec6SfBtbmj68bzWK"}) {
        data {
            alt
        }
    }

    recuVHORlPevLGlqZ: allAirtable(filter: {data: {group: {eq: "room20"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fixed(cropFocus: ATTENTION, height: 300, quality: 85) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recikyUgtm3DwWe8k: airtable(recordId: {eq: "recikyUgtm3DwWe8k"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(height: 300, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recziPgcigNJUbhKJ: airtable(recordId: {eq: "recziPgcigNJUbhKJ"}) {
        data {
            alt
        }
    }

    rec62FX4S0MAbvk3h: airtable(data: {identifier: {eq: "rec62FX4S0MAbvk3h"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec2mczTtQPvykUOG: airtable(recordId: {eq: "rec2mczTtQPvykUOG"}) {
        data {
            alt
        }
    }

    rec1usupYv5i2u0Za: airtable(recordId: {eq: "rec1usupYv5i2u0Za"}) {
        data {
            alt
        }
    }

    recBDY5cOOojKK5mn: airtable(data: {identifier: {eq: "recBDY5cOOojKK5mn"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recmM5UgCPReUx4k4: airtable(data: {identifier: {eq: "recmM5UgCPReUx4k4"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recvjaLHJwFdJcMZZ: airtable(recordId: {eq: "recvjaLHJwFdJcMZZ"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(height: 300, cropFocus: ATTENTION) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    recUlg8aGrC1gND7X: airtable(recordId: {eq: "recUlg8aGrC1gND7X"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(height: 300, cropFocus: ATTENTION) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    recBW9xXjKs66MFWZ: airtable(recordId: {eq: "recBW9xXjKs66MFWZ"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(height: 300, cropFocus: ATTENTION) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    reciSaKWZ4Vz9lWUK: airtable(recordId: {eq: "reciSaKWZ4Vz9lWUK"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(height: 300, cropFocus: ATTENTION) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    recolk2pDRq56lefs: airtable(data: {identifier: {eq: "recolk2pDRq56lefs"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recuZC3oyU650YG4R: airtable(data: {identifier: {eq: "recuZC3oyU650YG4R"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rectk3ob4xIAtXe6E: airtable(recordId: {eq: "rectk3ob4xIAtXe6E"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(cropFocus: ATTENTION, height: 300, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    recIA3pDRKp9EyeL8: airtable(recordId: {eq: "recIA3pDRKp9EyeL8"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(cropFocus: ATTENTION, height: 300, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    rec4jZVV94eyRhfYC: airtable(recordId: {eq: "rec4jZVV94eyRhfYC"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(cropFocus: ATTENTION, height: 300, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    recqwwiyTXvgqVuuW: airtable(recordId: {eq: "recqwwiyTXvgqVuuW"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(cropFocus: ATTENTION, height: 300, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    recIUv5l0lpy0kh0b: airtable(recordId: {eq: "recIUv5l0lpy0kh0b"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(cropFocus: ATTENTION, height: 300, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }

    recQ1BiG8ENmDFflK: airtable(data: {identifier: {eq: "recQ1BiG8ENmDFflK"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    gridTwo: allAirtable(filter: {data: {group: {eq: "room35"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(cropFocus: ATTENTION, maxHeight: 360, maxWidth: 360) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                title
                polaroid_class
            }         
        }
    }

    recXqVP8G2TkQh1qo: airtable(recordId: {eq: "recXqVP8G2TkQh1qo"}) {
        data {
            alt
            title
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
}  
`;